import { graphql } from 'gatsby';
import React from 'react';

import RichText from '../components/RichText/RichText.component';
import Seo from '../components/Seo.component';
import ImagesGallery from '../components/Products/Gallery.component';

import formatMoney from '../utils/formatMoney';
import {
  ProductDetailWrapperStyles,
  ProductDetailItemStyles,
} from './ProductDetailStyle.style';

export default function SingleProductPage({ data: { product } }) {
  const input = product._rawDescription;

  return (
    <>
      <Seo
        title={product.name}
        image={product.image[0].asset.url}
        description={product.name}
      />

      <ProductDetailWrapperStyles>
        <ProductDetailItemStyles>
          <div className="img-wrapper">
            <ImagesGallery product={product} />
          </div>

          <div className="content-wrapper">
            {product.coffees.map((cf) => (
              <h2 key={cf.id}>{cf.name}</h2>
            ))}

            <h1>{product.name}</h1>
            <RichText rText={input} />

            <div className="content-wrapper__price">
              <span>{formatMoney(product.price)}</span>
            </div>
          </div>
        </ProductDetailItemStyles>
      </ProductDetailWrapperStyles>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context via gatsby-node.js
export const query = graphql`
  query ($slug: String!) {
    product: sanityProduct(slug: { current: { eq: $slug } }) {
      name
      id
      price
      image {
        asset {
          url
          originalFilename
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      _rawDescription
      coffees {
        name
        id
        bestseller
      }
    }
  }
`;
