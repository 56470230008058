import styled from 'styled-components';
import tw from 'twin.macro';

export const ProductDetailWrapperStyles = styled.main`
  ${tw`container px-5 py-10 mx-auto text-gray-600`};
`;

export const ProductDetailItemStyles = styled.div`
  ${tw`flex flex-wrap mx-auto lg:w-4/5`};
  .img-wrapper {
    ${tw`object-cover object-center w-full rounded lg:h-auto lg:w-1/2`}
  }
  .content-wrapper {
    ${tw`w-full mt-6 lg:w-1/2 lg:pl-10 lg:py-6 lg:mt-0`}
    h2 {
      ${tw`text-sm tracking-widest text-gray-500`}
    }
    h1 {
      ${tw`mb-1 text-3xl font-medium text-gray-900`}
    }
    &__price {
      ${tw`flex pt-6 pb-5 mt-6 border-t-2 border-primary`}
      span {
        ${tw`text-3xl font-bold text-red-600`}
      }
    }
  }
`;
