import React from 'react';
import ImageGallery from 'react-image-gallery';
import ImageGalleryStyle from '../../styles/ImageGallery.style';

const ImagesGallery = ({ product }) => {
  const images = product?.image?.map((img) => ({
    original: img.asset.url,
    thumbnail: img.asset.url,
    originalClass: 'object-cover object-center rounded',
    srcSet: img.asset.gatsbyImageData.images.fallback.srcSet,
  }));

  return images ? (
    <ImageGalleryStyle>
      <ImageGallery
        // lazyLoad={true}
        showBullets={true}
        showNav={true}
        showFullscreenButton={false}
        showPlayButton={false}
        useBrowserFullscreen={false}
        thumbnailPosition="bottom"
        items={images}
      />
    </ImageGalleryStyle>
  ) : null;
};

export default ImagesGallery;
